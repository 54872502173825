<template>
    <section id="safetyConcept">
        <base-card>
          <v-container class="my-5">
            <v-skeleton-loader
                type="paragraph@3"
                :loading="loading"
                class="mt-5"
            >
              <template v-slot:default>
                <v-card-text
                    class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto my-5"
                    v-html="safetyConcept"
                >
                </v-card-text>
                <v-btn
                    color="secondary"
                    tonal
                    rounded
                    class="logo"
                    :href="safetyConceptDoc"
                    target="_blank"
                >
                  <v-icon
                      color="white"
                      size="30"
                  >mdi-file-pdf-box
                  </v-icon>
                  {{$t('safetyConcept.pdf')}}
                </v-btn>
              </template>
            </v-skeleton-loader>
          </v-container>
        </base-card>
    </section>
</template>

<script>

import {fetchData,query_SAFETYCONCEPT} from "@/utils/api"
import i18n from "@/plugins/i18n"
import showdown from "showdown"

export default {
  data: () => ({
    safetyConcept: {},
    safetyConceptDoc: '',
    loading: true
  }),
  methods: {
    getsafetyConcept: async () => {
      const query = query_SAFETYCONCEPT(i18n.locale)
      return await fetchData(query)
    }
  },
  async created() {
    const safetyConcept = await this.getsafetyConcept()
    const converter = new showdown.Converter()
    this.safetyConcept = converter.makeHtml(safetyConcept.textContent.text)
    this.safetyConceptDoc = safetyConcept.assetCollection.items[0].url
    this.loading = false

    this.$eventHub.$on('locale-changed', async()=> {
      this.loading = true
      const safetyConcept = await this.getsafetyConcept()
      const converter = new showdown.Converter()
      this.safetyConcept = converter.makeHtml(safetyConcept.textContent.text)
      this.safetyConceptDoc = safetyConcept.assetCollection.items[0].url
      this.loading = false
    })
  }
}
</script>
